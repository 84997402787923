Object.defineProperty(exports, '__esModule', {
  value: true
});

// Customizable Area Start
exports.apiContentType = 'application/json';
exports.apiFormContentType = 'multipart/form-data';
exports.getApiMethod = 'GET';
exports.postApiMethod = 'POST';
exports.getPrivacyPolApiEndPoint = 'bx_block_terms_and_conditions/privacy_policy';

exports.getTermsCondsApiEndPoint = 'terms_and_conditions/latest_record';
exports.getAllTermsCondsApiEndPoint = 'terms_and_conditions/terms_and_conditions';
exports.createTermsCondsApiEndPoint = 'terms_and_conditions/terms_and_conditions';
exports.getAccountGroupsApiEndPoint = 'account_groups/groups';
exports.setTermsCondsAcceptanceApiEndPoint = 'terms_and_conditions/accept_and_reject';

exports.getTermsAndConditionsEndpoint = "bx_block_terms_and_conditions/terms_and_conditions";
exports.getFooterDataEndpoint = "/bx_block_contact_us/social_media_links";
exports.contactUsGetUrl = "bx_block_contact_us/contact_us";
exports.createTermsConds = 'Create New'
exports.created = 'Created'
exports.tickAccept = 'Please Tick To Accept';
exports.updateTermsConds = 'Update';
exports.acceptedUsers = 'Accepted Users';
exports.saveTermsConds = 'Save';
exports.termsCondsUpdatedMassage = 'Terms and Conditions Updated Successfully';
exports.termsCondsList = 'Terms & Conditions List';
exports.termsConds = 'Terms & Conditions';
exports.privacytext = 'Privacy'
exports.policytext = 'Policy'
exports.termsCondsUserList = 'Users That Accepted List';
exports.termsText = "Terms";
exports.andText = "and";
exports.conditionsText = "Conditions";

exports.dateFormat = "MM-DD-YYYY";
exports.copyrightText = "© Credit Lift. All Rights Reserved"
  // Customizable Area End