import React from "react";
// Customizable Area Start
import { Box, Button, LinearProgress, styled, withStyles, MenuItem, TextField, Modal, Typography, CircularProgress, Select, MenuProps } from "@material-ui/core";
import { GaugeChart } from "../../../components/src/GaugeChart.web";
import OtpInput from "react-otp-input";
import {
  AreaChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Area,
  ResponsiveContainer
} from "recharts";
import {
  equifaxImg,
  experianImg,
  transUnionImg,
  dropdownImg,
  closeImg,
  accountVerifiedImg,
  emptyTasks
} from "./assets";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// Customizable Area End
import DashboardController, {
  Props,
  webConfigJSON
} from "./DashboardController.web";

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  getCreditScoreBox = (name: string, imageSource: string) => (
    <Box>
      <ImageWrapper>
        <img
          className="logo-image"
          style={{
            paddingTop: name === webConfigJSON.transUnionText ? "0" : "35px"
          }}
          src={imageSource}
          alt={name}
        />
      </ImageWrapper>
      <CreditScoreHeadingText>{`${webConfigJSON.checkYourText} ${name} ${
        webConfigJSON.creditScoreText
      }`}</CreditScoreHeadingText>
      <CreditScoreDescriptionText>{`${
        webConfigJSON.makeTheRightCreditDecisionsWithText
      } ${name} ${
        webConfigJSON.increaseYourCreditScoreText
      }`}</CreditScoreDescriptionText>
      <CheckScoreButton
        variant="outlined"
        data-test-id={`${name}Score`}
        onClick={() => this.handleCheckScore(name, imageSource)}
      >
        {webConfigJSON.checkScoreLabel}
      </CheckScoreButton>
    </Box>
  );

  creditScoreValueBox = (
    name: string,
    imageSource: string,
    updatedOn: string,
    nextUpdate: string,
    score: number,
    diff: number
  ) => (
    <Box>
      <ScoreValueSection>
        <ValueImageWrapper>
          <img src={imageSource} alt={name} />
        </ValueImageWrapper>
        <ViewReportButton onClick={() => this.goToReport(name)} data-test-id={`${name}-view-report`}>
          {webConfigJSON.viewReportLabel}
        </ViewReportButton>
      </ScoreValueSection>
      <UpdatedOnText>{`${
        webConfigJSON.updateOnText
      } ${updatedOn}`}</UpdatedOnText>
      <ScoreWrapper>
        <GaugeChart value={score} diff={diff} />
      </ScoreWrapper>
      <NextUpdateWrap>
        <Box>{`${webConfigJSON.nextUpdateLabel} ${nextUpdate}`}</Box>
      </NextUpdateWrap>
    </Box>
  );

  getCreditHistoryMenuItem = (value: string) => (
    <MenuItemContent data-test-id={`${value}-option`} value={value} disableRipple>
      <Box className={value === this.state.selectedCreditMenu ? "activate-menu" : "not-activate-menu"}>{value}</Box>
    </MenuItemContent>
  );

  TaskSection = () => {  
  const {userType, pendingTasks, allTasks, selectedUser} = this.state;
  const userId = selectedUser ? selectedUser.id : 0;
  const tasks = userType === "User" ? pendingTasks : allTasks;
  const noTaskTxt = userType === "User" ? "You don`t have any pending tasks" : "You don`t have any tasks";
  const title = userType === "User" ? webConfigJSON.pendingTasksLabel : webConfigJSON.allTasksLabel;
    return (
    <PendingTasksSection>
      <Box className="title-wrapper">
      <p className="title-text">{title}</p>
      {(userType !== "User" && allTasks.length>3) && <p onClick={()=>this.goToAllTasks(userId)} className='viewall-text'>View All</p>}
      </Box>
        {tasks.length > 0 ?
          tasks.map((item) => (
          <TaskListWrapper style={{ 
            borderLeft: `3px solid ${this.getColorValue(item.status)}`, backgroundColor: this.getBackgroundColorValue(item.status) }}>
            <Box key={item.title}>
              <Box width={"68%"}>
                <Box className="task-title-wrap">
                  <p className="title-text">{item.title}</p>
                  <p className="last-updated-text">
                    {`${webConfigJSON.lastUpdatedLabel} ${item.lastUpdated}`}
                  </p>
                </Box>
                <p className="score-label-text">{item.description}</p>
              </Box>
              <Box className="status-detail-wrapper">
                <StatusWrapper>
                  <p>{item.status}</p>
                  <p className="percentage-symbol">
                    {item.percentage}
                    {webConfigJSON.percentSymbol}
                  </p>
                </StatusWrapper>
                <BorderLinearProgress
                  variant="determinate"
                  value={item.percentage}
                  className={`linear-progress-bar statusbg-${item.status.toLowerCase().split(' ').join('-')}`}
                />
              </Box>
              <Box className="status-detail-wrapper-mobile">
                <CircularProgress
                  className="inner-progress-bar-mobile"
                  thickness={4}
                  variant="determinate"
                  value={100}
                  style={{ color: this.getBackColorValue(item.status) }}
                />
                <CircularProgress
                  className="outer-progress-bar-mobile"
                  variant="determinate"
                  value={item.percentage}
                  thickness={4}
                  style={{ color: this.getColorValue(item.status) }}
                />
                <Box className="circular-progress-label-mobile">
                  {item.percentage}%
                </Box>
              </Box>
            </Box>
          </TaskListWrapper>
          )):
          <Box className="notask-wrapper">
          <img src={emptyTasks} alt={"emptytask"} />
          <Box className="notask-txt">{noTaskTxt}</Box>
          <Box className="notast-description">{"You’ll see the tasks here if any added"}</Box>
          </Box>
          }
    </PendingTasksSection>
  )}

  renderUserDashboard = () => {
    let formattedDate = "";
    if(this.state.selectedUser) {
      formattedDate = this.formatDate(this.state.selectedUser.attributes.updated_at).replace(",", "");
    }
    return (
      <>
        <UpperSection>
            {this.state.userType === "Agent" && this.state.selectedUser && (
              <Box className="selected-user-box">
                <ArrowBackIcon onClick={this.goBackToAgentDashboard.bind(this)} className="go-back-arrow"/>
                <Typography className="selected-user-name">{`${this.state.selectedUser.attributes.user_name}'s Details`}</Typography>
                <Typography className="selected-user-last-updated">Last updated on {formattedDate}</Typography>
              </Box>
            )}
            {!this.state.isOpenTransUnion
              ? this.getCreditScoreBox(
                  webConfigJSON.transUnionText,
                  transUnionImg
                )
              : this.creditScoreValueBox(
                  webConfigJSON.transUnionText,
                  transUnionImg,
                  "11 July, 2023",
                  "20 August, 2023",
                  420, 
                  100
                )}
            {!this.state.isOpenEquiefax
              ? this.getCreditScoreBox(webConfigJSON.equifaxText, equifaxImg)
              : this.creditScoreValueBox(
                  webConfigJSON.equifaxText,
                  equifaxImg,
                  "11 July, 2023",
                  "20 August, 2023",
                  800,
                  -100
                )}
            {!this.state.isOpenExperian
              ? this.getCreditScoreBox(webConfigJSON.experianText, experianImg)
              : this.creditScoreValueBox(
                  webConfigJSON.experianText,
                  experianImg,
                  "11 July, 2023",
                  "20 August, 2023",
                  550,
                  550
                )}
          </UpperSection>
          <LowerSection>
          {this.TaskSection()}
            <CreditHistorySection>
              <Box className="credit-history-head">
                <p>{webConfigJSON.creditHistoryLabel}</p>
                <Select
                  MenuProps={dashboardMenuProps}
                  className="credit-history-select"
                  data-test-id="credit-history-dropdown"
                  IconComponent={() => <img style={{ position: "absolute", right: 14, pointerEvents: "none" }} src={dropdownImg} />}
                  value={this.state.selectedCreditMenu}
                  variant="outlined"
                  onChange={this.handleCreditMenuValue}
                >
                  {this.getCreditHistoryMenuItem(webConfigJSON.transUnionText)}
                  {this.getCreditHistoryMenuItem(webConfigJSON.equifaxText)}
                  {this.getCreditHistoryMenuItem(webConfigJSON.experianText)}
                </Select>
              </Box>
              <Box className="responsive-container">
                <ResponsiveContainer width="100%" height={320}>
                  <AreaChart
                    data={this.state.chartData}
                    margin={{
                      top: 30,
                      right: 30
                    }}
                  >
                    <defs>
                      <linearGradient id="score" x1="0" y1="0" x2="0" y2="1">
                        <stop stopColor="#FFBA65" />
                        <stop offset="1" stopColor="#FFF8EF" />
                      </linearGradient>
                    </defs>
                    <XAxis
                      tick={{
                        fill: "#333",
                        opacity: 0.7,
                        fontFamily: "Urbanist",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: 500
                      }}
                      tickLine={false}
                      dataKey="name"
                    />
                    <YAxis
                      ticks={[350,450, 550, 650, 750, 850]}
                      domain={[300, 850]}
                      tick={{
                        fill: "#333",
                        opacity: 0.7,
                        fontFamily: "Urbanist",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: 500
                      }}
                      tickLine={false}
                    />
                    <CartesianGrid stroke="#F9F4EE" strokeWidth="2px" />
                    <Tooltip />
                    <Area
                      type="monotone"
                      dataKey="score"
                      stroke="#FD8D02"
                      strokeWidth="1px"
                      fillOpacity={1}
                      fill="url(#score)"
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </Box> 
            </CreditHistorySection>
          </LowerSection>
      </>
    ) 
  }

  renderAgentDashboard = () => {
    return (
      <AgentDashboardContainer>
        <Typography>Assigned Users</Typography>

        {this.state.allAssignedUsers && this.state.allAssignedUsers.length > 0 ? this.state.allAssignedUsers.map((singleUser, index) => {
          const formattedDate = this.formatDate(singleUser.attributes.updated_at).replace(",", "");
          return (
            <Box key={singleUser.id} className="user-card" data-test-id={`single-user-card-${index}`} onClick={this.selectUser.bind(this, singleUser)}>
              {singleUser.attributes.image_url ? <img className="user-icon-img" src={singleUser.attributes.image_url} alt={`user-icon-${index}`}/> : <AccountCircleIcon className="user-icon" /> }
              <Box>
                <Typography className="user-name">{singleUser.attributes.user_name}</Typography>
                <Typography className="user-email">{singleUser.attributes.email}</Typography>
              </Box>
              <Typography className="user-last-updated">Last updated on {formattedDate}</Typography>
            </Box>
          )
        }) : (
          <Box className="user-card">
            <Typography className="no-users-available">No Users Available</Typography>
          </Box>
        )}
      </AgentDashboardContainer>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer
      <Box className='topScroll'>
        <NavigationMenu 
          id={this.props.id} 
          navigation={this.props.navigation} 
          screenName="Dashboard"
          isDrawer
          isNotification
          isDashboard
          fullWidthNavigation
        />
        <MobileTop>
          <Box className="title">{webConfigJSON.textHey} {this.state.userName}{"!"}</Box>
          <Box className="description">{webConfigJSON.textYourCreditScoreFor}</Box> 
        </MobileTop>
        <MainContainer>
          <ModalWrapper
            open={this.state.modalData.isModalOpen}
          >
              <Box className="modal-section-wrapper" style={{ textAlign: this.state.modalData.modalType === "AccountVerified" ? "center" : "left" }}>
                {this.state.modalData.modalType === "AccountVerified" ? <Box><img className="account-verified-image" src={accountVerifiedImg} /></Box>
                  :
                  <Box className="image-section-wrapper">
                    <img src={this.state.modalData.modalImg} height="40" />
                    <CloseButtonWrapper
                      data-test-id="close-button"
                      src={closeImg}
                      onClick={this.handleModalClose}
                    />
                  </Box>
                }
                <Box className="modal-title">{this.getModalHeadingText()}</Box>
                <Box className="modal-description-text">
                  {this.getModalDescriptionText()}
                </Box>
                {this.state.modalData.modalType === "ConnectAccount" && (
                  <>
                    <Box className="modal-input-label">
                      {webConfigJSON.emailFieldLabel}
                    </Box>
                    <TextFieldWrapper
                      className={this.state.isEmailInvalid ? "error-email-field" : "email-field"}
                      data-test-id="email-input-field"
                      variant="outlined"
                      type="text"
                      value={this.state.emailValue}
                      onChange={this.handleEmailValue}
                      placeholder="Username/Email ID"
                    />
                    {this.state.isEmailInvalid && 
                      <Box className="error-text">
                        {webConfigJSON.emailFieldErrorText}
                      </Box>
                    }
                  </>
                )}
                {this.state.modalData.modalType === "VerifyOTP" && (
                  <Box className={"otp-input-wrapper"}>
                    <Box className={this.state.isOTPInvalid ? "invalid-otp-input" : "otp-input"}>
                      <OtpInput
                        data-test-id="otp-input"
                        value={this.state.otpValue}
                        onChange={this.handleOTP}
                        numInputs={4}
                        isInputNum={true}
                      />
                      {this.state.isOTPInvalid && 
                        <Box className="error-text" data-test-id="invalid-otp">
                          {webConfigJSON.otpFieldErrorText}
                        </Box>
                      }
                    </Box>
                    <Box className="under-otp-wrapper">
                      <Box>{webConfigJSON.timeLeftText} <span className="remaining-time-text">{this.getRemainingTime()}</span></Box>
                      <Box>{webConfigJSON.didNotReceiveOtpText} <span data-test-id="resend-button" className={`${this.state.remainingSeconds ? 'disabled-resend-text' : 'resend-text'}`} onClick={this.handleResendOTP}>{webConfigJSON.resendText}</span></Box>
                    </Box>
                  </Box>
                )}
                <Button
                  className={
                    this.state.modalData.modalType !== "AccountVerified"
                      ? "send-otp-button"
                      : "account-verified-button"
                  }
                  data-test-id="modal-submit-button"
                  onClick={this.handleModalSubmit}
                >
                  {this.getModalButtonText()}
                </Button>
              </Box>
          </ModalWrapper>

          {this.state.userType === "User" || this.state.selectedUser !== null ? this.renderUserDashboard() : this.renderAgentDashboard()}

        </MainContainer>
      </Box>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const MobileTop = styled(Box)({
  height: "90px",
  background: "#019F48",
  padding: "6px 24px 16px",
  "& .title": {
    color: "#FFF",
    fontFamily: "Urbanist",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal"
  },
  "& .description": {
    color: "#FFF",
    fontFamily: "Urbanist",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "18px",
    marginTop: "8px"
  },
  "@media only screen and (min-width: 768px)": {
    display: "none"
  }
})
const ModalWrapper = styled(Modal)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "& .modal-section-wrapper": {
    borderRadius: "20px",
    background: "#FFF",
    padding: "50px",
    fontFamily: "Urbanist",
    fontStyle: "normal",
    "@media only screen and (max-width: 767px)": {
      margin: "0 24px",
      padding: "24px"
    },
    "&>button": {
      height: "56px",
      borderRadius: "12px",
      fontFamily: "Urbanist",
      fontSize: "22px",
      fontWeight: 600,
      lineHeight: "30px",
      textTransform: "none",
      marginTop: "20px",
      "@media only screen and (max-width: 767px)": {
        marginTop: "4px",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "normal"
      },
    }
  },
  "& .account-verified-image": {
    padding: "0 213px",
    "@media only screen and (max-width: 767px)": {
      height: "150px",
      padding: "0"
    }
  },
  "& .image-section-wrapper": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  "& .modal-title": {
    margin: "20px 0",
    fontSize: "30px",
    fontWeight: "700",
    lineHeight: "35px",
    color: "#333",
    "@media only screen and (max-width: 767px)": {
      color: "#292D32",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "18px",
      margin: "20px 0 8px"
    },
  },
  "& .modal-description-text": {
    fontSize: "24px",
    lineHeight: "34px",
    color: "#333",
    marginBottom: "20px",
    maxWidth: "654px",
    "@media only screen and (max-width: 767px)": {
      fontSize: "12px",
      lineHeight: "19px",
      opacity: 0.5
    }
  },
  "& .modal-input-label": {
    fontSize: "18px",
    fontWeight: "500",
    color: "#333",
    lineHeight: "35px",
    marginBottom: "4px",
    "@media only screen and (max-width: 767px)": {
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "normal",
      marginBottom: "8px"
    }
  },
  "& .error-text": {
    color: "#F00",
    fontSize: "18px",
    fontWeight: "500",
    lineHeight: "35px",
    marginTop: "4px",
    "@media only screen and (max-width: 767px)": {
      fontSize: "12px",
      lineHeigtht: "25px"
    }
  },
  "& .send-otp-button": {
    width: "100%",
    background: "#019F48",
    color: "#FFF",
    "&:hover": {
      background: "#019F48",
      color: "#FFF",
    }
  },
  "& .account-verified-button": {
    width: "190px",
    border: "1px solid #9E9E9E",
    background: "#FFF",
    color: "#333"
  },
  "& .otp-input-wrapper": {
    "&>div>div": {
      gap: "30px",
      "@media only screen and (max-width: 767px)": {
        gap: "12px"
      }
    },
    "& input": {
      width: "141px !important",
      height: "100px !important",
      borderRadius: "12px",
      background: "#F4FAF7",
      color: "#019F48",
      fontFamily: "Urbanist",
      fontSize: "40px",
      fontWeight: 700,
      "@media only screen and (max-width: 800px)": {
        width: "100% !important",
        height: "80px !important",
        fontSize: "30px"
      },
      "@media only screen and (max-width: 767px)": {
        width: "60px !important",
        height: "50px !important",
        fontSize: "20px"
      }
    },
    "& .otp-input input": {
      border: "1px solid #CEE1D6",
    },
    "& .invalid-otp-input input": {
      border: "1px solid #FF0000",
    },
    "& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
      WebkitAppearance: "none",
      margin: 0
    },
    "& input[type=number]" : {
      MozAppearance: "textfield"
    }
  },
  "& .under-otp-wrapper": {
    padding: "20px 0",
    display: "flex",
    justifyContent: "space-between",
    color: "#333",
    fontFamily: "Urbanist",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "35px",
    "@media only screen and (max-width: 767px)": {
      fontSize: "12px",
      lineHeight: "19px"
    }
  },
  "& .resend-text": {
    color: "#019F48",
    fontWeight: 600,
    textDecorationLine: "underline",
    cursor: "pointer"
  },
  "& .disabled-resend-text": {
    color: "#019F48",
    fontWeight: 600,
    textDecorationLine: "underline",
    opacity: 0.4,
    cursor: "default"
  },
  "& .remaining-time-text": {
    fontWeight: 700
  },
  "& .email-field": {
    marginBottom: "20px",
    "&>div>fieldset": {
      border: "1px solid #CEE1D6",
    },
    "& .MuiOutlinedInput-root fieldset": {
      borderColor: "#CEE1D6"
    }
  },
  "& .error-email-field": {
    "&>div>fieldset": {
      border: "1px solid #FF0000",
    },
    "& .MuiOutlinedInput-root fieldset": {
      borderColor: "#FF0000"
    }
  }
});

const dashboardMenuProps: Partial<MenuProps> = {
  transformOrigin:{
    horizontal: "left",
    vertical: "top",
  },
  anchorOrigin:{
    vertical: "bottom",
    horizontal: "left"
  },
  PaperProps: {
    style: {
      marginTop: "4px",
      border: "1px solid rgba(1, 159, 72, 0.18)",
      borderRadius: "4px",
      boxShadow: "0px 8px 30px 0px rgba(11, 109, 55, 0.10)",
      background: "#FFF"
    }
  },
  getContentAnchorEl: null,
  MenuListProps: {
    style: {
      padding: 0,
    },
  },
}



const TextFieldWrapper = styled(TextField)({
  width: "100%",
  borderRadius: "12px",
  height: "52px",
  background: "#F4FAF7",
  "&>div>fieldset": {
    height: "56px",
    borderRadius: "12px",
  },
  "&>div>input": {
    height: "52px",
    boxSizing: "border-box",
    color: "#333",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "35px",
    fontFamily: "Urbanist",
    paddingLeft: "20px",
    paddingRight: "20px",
    "&::placeholder": {
      opacity: 1,
      color: "#9E9E9E"
    }
  }
});

const MenuItemContent = styled(MenuItem)({
  padding: 0,
  "@media screen and (max-width: 600px)" : {
    minHeight: "auto",
  },
  '& .activate-menu': {
    color: "#019F48",
    fontWeight: 600
  },
  '& .not-activate-menu': {
      color: "#333",
      fontWeight: 500
  },
  '&>div': {
    padding: "4px 14px",
    background: "#FFF",
    lineHeight: "normal",
    fontSize: "16px",
    fontFamily: "Urbanist",
    width: "100%",
    '&:hover': {
        background: 'rgba(1, 159, 72, 0.15)'
    }
  }
});

const BorderLinearProgress = withStyles({
  root: {
    height: 10,
    borderRadius: 30,
    marginTop: 10
  },
  colorPrimary: {
    backgroundColor: "#d9d9d9"
  },
  bar: {
    borderRadius: 30,
    backgroundColor: "#FD8D02"
  }
})(LinearProgress);

const MainContainer = styled(Box)({
  minHeight: "100vh",
  background: "#F3FAF7",
  padding: "30px 50px",
  display: "flex",
  flexDirection: "column",
  rowGap: "30px",
  fontFamily: "Urbanist",
  fontStyle: "normal",
  "& p": {
    margin: 0
  },
  "@media only screen and (max-width: 767px)": {
    padding: "24px",
    background: "#FFF",
    rowGap: "24px"
  }
});

const ImageWrapper = styled(Box)({
  height: "177px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "@media only screen and (max-width: 1050px)": {
    height: "140px",
  },
  "@media only screen and (max-width: 767px)": {
    height: "85px",
  },
  "& .logo-image": {
    height: "auto",
    "@media only screen and (max-width: 1100px)": {
      height: "40px"
    },
    "@media only screen and (max-width: 767px)": {
      height: "30px"
    }
  }
});

const CloseButtonWrapper = styled("img")({
  cursor: "pointer"
});

const UpperSection = styled(Box)({
  display: "flex",
  gap: "30px",
  flexWrap: "wrap",
  "@media only screen and (max-width: 767px)": {
    flexDirection: "column",
    gap: "24px"
  },
  "& .selected-user-box": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "1.5rem",
    padding: "1.9rem",
    backgroundColor: "#fff",
    borderRadius: "20px",
    flexWrap: "wrap",
    width: "100%",
    height: "auto",
    "& .go-back-arrow": {
      cursor: "pointer"
    },
    "& .selected-user-name": {
      fontSize: "20px",
      color: "#333333",
      fontWeight: 700,
      fontFamily: "Urbanist",
    },
    "& .selected-user-last-updated": {
      fontSize: "1rem",
      color: "#9E9E9E",
      fontWeight: 500,
      fontFamily: "Urbanist",
    },
  },
  "&>div": {
    width: "calc(33.3% - 20px)",
    padding: "0 30px 70px",
    borderRadius: "20px",
    border: "1px solid rgba(1, 159, 72, 0.18)",
    textAlign: "center",
    background: "#FFF",
    boxShadow: "0px 8px 30px 0px rgba(11, 109, 55, 0.10)",
    minWidth: "280px",
    height: "417px",
    boxSizing: "border-box",
    "@media only screen and (max-width: 767px)": {
      width: "100%",
      height: "auto",
      padding: "0 33px 32px",
      border: "1px solid #E3E3E3",
      boxShadow: "0px 2.10968px 12.65807px 0px rgba(0, 0, 0, 0.25)"
    }
  }
});

const AgentDashboardContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "30px",
  padding: "1.9rem",
  backgroundColor: "#fff",
  borderRadius: "20px",
  flexWrap: "wrap",
  "@media only screen and (max-width: 767px)": {
    flexDirection: "column",
    gap: "24px"
  },
  "& > p": {
    fontWeight: 700,
    fontSize: "1.3rem",
    lineHeight: "30px",
    fontFamily: "Urbanist",
  },
  "& .user-card": {
    border: "1px solid #DBDBDB",
    borderRadius: "8px",
    padding: "1.8rem",
    display: "flex",
    justifyContent: "space-between",
    cursor: "pointer",
    "& .user-icon-img": {
      width: "50px",
      height: "50px",
      borderRadius: "100%"
    },
    "& .user-icon": {
      height: "50px",
      width: "50px",
    },
    "& > div": {
      marginRight: "auto",
      marginLeft: "1.5rem",
      "& .user-name": {
        fontSize: "18px",
        color: "#333333",
        fontWeight: 600,
        lineHeight: "30px",
        fontFamily: "Urbanist",
      },
      "& .user-email": {
        fontSize: "14px",
        color: "#333333",
        fontWeight: 500,
        lineHeight: "20px",
        fontFamily: "Urbanist",
      }
    },
    "& .user-last-updated": {
      fontSize: "1rem",
      color: "#9E9E9E",
      fontWeight: 500,
      lineHeight: "20px",
      fontFamily: "Urbanist",
    },
    "& .no-users-available": {
      textAlign: "center",
      fontSize: "20px",
      color: "#9E9E9E",
    }
  }
});

const CreditScoreDescriptionText = styled(Box)({
  color: "#333",
  textAlign: "center",
  fontFamily: "Urbanist",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "26px",
  margin: "10px 0 25px",
  "@media only screen and (max-width: 1400px)": {
    fontSize: "16px",
  },
  "@media only screen and (max-width: 767px)": {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "19px",
    opacity: 0.5
  }
});

const StatusWrapper = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  "&>p": {
    color: "#333",
    fontFamily: "Urbanist",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "30px"
  },
  "& .percentage-symbol": {
    fontWeight: 600
  }
});

const CreditScoreHeadingText = styled(Box)({
  color: "#333",
  textAlign: "center",
  fontFamily: "Urbanist",
  fontSize: "22px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "30px",
  "@media only screen and (max-width: 1400px)": {
    color: "#292D32",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "18px"
  },
  "@media only screen and (max-width: 767px)": {
    fontSize: "16px",
  }
});

const CheckScoreButton = styled(Button)({
  borderRadius: "12px",
  border: "1px solid #019F48",
  padding: "20px 50px",
  color: "#019F48",
  textTransform: "none",
  fontFamily: "Urbanist",
  fontSize: "22px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "30px",
  height: "56px",
  "@media only screen and (max-width: 1050px)": {
    padding: 0,
    width: "100%"
  },
  "@media only screen and (max-width: 767px)": {
    height: "44px",
    fontSize: "16px",
    fontWeight: 500,
    width: "125px",
    padding: "12px",
  }
});

const ViewReportButton = styled(Button)({
  marginTop: "30px",
  width: "106px",
  height: "30px",
  borderRadius: "8px",
  background: "#019F48",
  color: "#FFF",
  fontFamily: "Urbanist",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "35px",
  textTransform: "none",
  "&:hover": {
    background: "#019F48"
  }
});

const ScoreValueSection = styled(Box)({
  display: "flex",
  justifyContent: "space-between"
});

const TaskListWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  fontFamily: "Urbanist",
  fontStyle: "normal",
  gap: "20px",
  marginBlock: "12px",
  borderRadius: "8px",
  "&>div": {
    padding: "6px 20px",
    height: "90px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "@media only screen and (max-width: 1400px)": {
      padding: "15px",
      height: "auto"
    },
    "@media only screen and (max-width: 767px)": {
      padding: "10px",
    }
  },
  "& .task-title-wrap": {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    flexWrap: 'wrap',
    "@media only screen and (max-width: 767px)": {
      gap: "8px"
    }
  },
  "& .title-text": {
    color: "#333",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "30px",
    "@media only screen and (max-width: 767px)": {
      fontSize: "12px",
      fontWeight: 700,
      lineHeight: "normal"
    }
  },
  "& .last-updated-text": {
    color: "#87898C",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "normal",
    "@media only screen and (max-width: 767px)": {
      fontSize: "8px",
    }
  },
  "& .score-label-text": {
    color: "#333",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "35px",
    textWrap: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: 'nowrap',
    maxWidth: '90%',
    "@media only screen and (max-width: 767px)": {
      fontSize: "12px",
      lineHeight: "normal",
      marginTop: "6px",
    }
  },
  "& .status-detail-wrapper": {
    width: "167px",
    "@media only screen and (max-width: 767px)": {
      display: "none"
    },
    "& .linear-progress-bar": {
      background: "#D9D9D9",
      borderRadius: "30px",
      height: "10px"
  },
  "& .statusbg-pending": {
      "& .MuiLinearProgress-barColorPrimary": {
          backgroundColor: "#FF0000"
      }
  },
  "& .statusbg-not-started": {
      "& .MuiLinearProgress-barColorPrimary": {
          backgroundColor: "#D9D9D9"
      }
  },
  "& .statusbg-inprogress": {
      "& .MuiLinearProgress-barColorPrimary": {
          backgroundColor: "#FD8D02"
      }
  },
  "& .statusbg-completed": {
      "& .MuiLinearProgress-barColorPrimary": {
          backgroundColor: "#019F48"
      }
  },
  },
  "& .status-detail-wrapper-mobile": {
    display: "block",
    position: "relative",
    height: "27px",
    width: "27px",
    "@media only screen and (min-width: 768px)": {
      display: "none"
    },
    "& .MuiCircularProgress-colorPrimary": {
      color: "#D9D9D9"
    },
    "& .inner-progress-bar-mobile": {
      height: "27px !important",
      width: "27px !important"
    },
    "& .outer-progress-bar-mobile": {
        color: "#FD8D02",
        top: 0,
        right: 0,
        position: "absolute",
        transform: "rotate(-225deg) !important",
        height: "27px !important",
        width: "27px !important",
        "& circle": {
            strokeLinecap: "round"
        }
      }
    },
    "& .circular-progress-label-mobile": {
      position: "absolute",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      top: 0,
      left: 0,
      height: "27px",
      width: "27px",
      fontSize: "7px",
      fontWeight: 600
    },
});

const ValueImageWrapper = styled(Box)({
  height: "70px",
  display: "flex",
  alignItems: "center",
  "&>img": {
    width: "120px"
  }
});

const ScoreWrapper = styled(Box)({
  margin: "0 auto 20px",
  height: "235px",
  boxSizing: "border-box",
  paddingTop: "22px"
});

const UpdatedOnText = styled(Box)({
  color: "#9E9E9E",
  fontFamily: "Urbanist",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  textAlign: "left"
});

const NextUpdateWrap = styled(Box)({
  padding: "10px",
  borderRadius: "4px",
  background: "rgba(253, 141, 2, 0.08)",
  "&>div": {
    color: "#FD8D02",
    fontFamily: "Urbanist",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal"
  }
});

const LowerSection = styled(Box)({
  display: "flex",
  gap: "30px",
  "@media only screen and (max-width: 1000px)": {
    flexDirection: "column",
  },
  "@media only screen and (max-width: 767px)": {
    gap: "24px"
  },
  "& .responsive-container": {
    "@media only screen and (max-width: 767px)": {
      marginLeft: "-32px"
    }
  },
  "&>div": {
    width: "50%",
    boxSizing: "border-box",
    borderRadius: "20px",
    border: "1px solid rgba(1, 159, 72, 0.18)",
    background: "#FFF",
    boxShadow: "0px 8px 30px 0px rgba(11, 109, 55, 0.10)",
    maxHeight: "410px",
    overflow: "auto",
    "@media only screen and (max-width: 1000px)": {
      width: "100%"
    },
    "@media only screen and (max-width: 767px)": {
      borderRadius: "12px"
    },
    "&>p": {
      color: "#333",
      fontFamily: "Urbanist",
      fontSize: "22px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "35px",
      "@media only screen and (max-width: 767px)": {
        color: "#292D32",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "18px"
      }
    }
  }
});

const PendingTasksSection = styled(Box)({
  padding: "25px 30px",
  "@media only screen and (max-width: 767px)": {
    padding: "12px"
  },
  "& .title-wrapper": {display: "flex", justifyContent: "space-between", marginBottom: '15px'},
  "& .title-text": {
    fontSize: "22px",
    fontWeight: 700, 
    textWrap: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: 'nowrap',
    maxWidth: '55%'
  },
  "& .viewall-text": {fontSize: "18px", fontWeight: 600, color: "#019F48", cursor:"pointer"},
  "& .notask-wrapper": {
    display: "flex",
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: "center",
    margin: "15px",
    '&>img': {
      height: "235px",
      width: "235px",
      "@media screen and (max-width: 767px)" : {
          width: "140px",
          height: "140px"
    }
  },
    "& .notask-txt": {
      fontWeight: 600,
      fontSize: "25px",
      textAlign: 'center',
      "@media screen and (max-width: 767px)" : {
        fontSize: "18px",
        color: "#333",
        lineHeight: "normal"
    },
    },
    "& .notast-description": {
      fontSize: "22px",
      lineHeight: "33px",
      color: "#9E9E9E",
      textAlign: 'center',
      "@media screen and (max-width: 767px)" : {
          fontSize: "12px",
          lineHeight: "normal"
      },
  }
  },
});

const CreditHistorySection = styled(Box)({
  padding: "20px 10px 30px",
  "@media only screen and (max-width: 767px)": {
    borderRadius: "12.386px",
    border: "0.619px solid rgba(1, 159, 72, 0.18)",
    background: "#FFF",
    boxShadow: "0px 4.95455px 18.57955px 0px rgba(11, 109, 55, 0.10)"
  },
  "& .credit-history-head": {
    display: "flex",
    justifyContent: "space-between",
    "&>p": {
      paddingLeft: "30px",
      color: "#333",
      fontSize: "22px",
      fontWeight: 700,
      lineHeight: "35px",
      "@media only screen and (max-width: 767px)": {
        paddingLeft: "5px",
        fontSize: "13px",
        lineHeight: "21px",
      }
    },
    "& .credit-history-select": {
      background: "#F4FAF7",
      height: "34px",
      width: "122px",
      fontSize: "15px",
      fontWeight: 500,
      color: "#333",
      lineHeight: "35px",
      marginRight: "30px",
      "@media only screen and (max-width: 767px)": {
        marginRight: 0
      },
      "& .MuiSelect-selectMenu": {
        background: "none",
        padding: "0 8px 0 0",
        textOverflow: "unset",
        fontFamily: "Urbanist",
        paddingLeft: "14px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #CEE1D6",
        borderRadius: "4px"
      }
    }
  }
});
// Customizable Area End
